import '~/scss/screen.scss';
import '@rainbow-me/rainbowkit/styles.css';

const GTM = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;
import { darkTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { WagmiConfig } from 'wagmi';

import Konami from '~/components/konami';
import QueryClientProvider from '~/contextProviders/QueryClientProvider';
import { chains, wagmiClient } from '~/utils/walletWrapper';


function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>Peacefall 👊✌️</title>
      </Head>
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GTM}`}
      />
      <Script
        strategy="afterInteractive"
        id="GTM"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || []
            function gtag(){dataLayer.push(arguments)}
            gtag('js', new Date())

            gtag('config', '${GTM}')
        `,
        }}
      />
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={chains} theme={darkTheme()}>
          {/* <YourApp /> */}
          <QueryClientProvider>
            <Component {...pageProps} />
          </QueryClientProvider>
        </RainbowKitProvider>
      </WagmiConfig>
      <Konami />
    </>
  );
}

export default MyApp;
