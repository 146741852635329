// NFT Constants
export const PRICE = 0.1;
export const MAX_SUPPLY = 8192;
export const MAX_MINT = 10;
export const MAX_SUPPORTER = 10;
export const MAX_OG = 4;
// calcualted via https://www.epochconverter.com/
export const AUTH_COOKIE_NAME = 'game_auth';
export const BATTLE_EIGHT_ENDS = 1656007200000;

export const TOURNAMENT_START_DATE = 1659463200000; // Aug 2nd, 2pm EST

export const CURRENT_BATTLE_ENDS = BATTLE_EIGHT_ENDS;
export const CURRENT_ACTIVE_BATTLE = 9; // zero based
export const FINAL_ROUND = 9;
export const CHOICE_TIME_ENDS = 1655830800000;
// export const DISCORD_GUILD_ID = '879784812959916103'; // Jar test Discord
export const DISCORD_GUILD_ID = '928284874392092762'; // Peacefall Discord
export const DISCORD_CLIENT_ID = '957375830265299084';
// export const DISCORD_REDIRECT_URI = 'http://localhost:3000/discord/callback'; // local dev only
export const DISCORD_REDIRECT_URI = 'https://peacefall.xyz/discord/callback';
export const DISCORD_PEACEFALL_URL =
  'https://discord.com/channels/928284874392092762';

export const DB_TABLE_NAME_DISCORD = 'nftbot.users';
export const DB_TABLE_NAME_EMAIL_PROD =
  'peacefall-game-production.email-verification-2';
export const DB_TABLE_NAME_EMAIL_DEV =
  'peacefall-game-development.email-verification';

export const FILTERS = {
  characters: 'characters',
  levels: 'levels',
  syndicates: 'syndicates',
  types: 'types',
  hps: 'hps',
  owned: 'owned',
  context: 'context',
};

export const BEEHIIV_PUBLICATION_ID = '7523425d-cbd4-4ed1-b595-228c140700a8';
export const VERIFY_EMAIL_FROM_ADDRESS = '<peacefall@email.peacefall.xyz>';
export const VERIFY_EMAIL_TEMPLATE_NAME = 'verification-email';
export const BUDDY_THE_BOT_URL = 'https://buddythebot.peacefall.xyz';

export const OPENSEA_COLLECTION_URL = 'https://opensea.io/collection/peacefall';
export const OPENSEA_POTION_COLLECTION_URL = 'https://opensea.io/collection/peacefallpotion';
export const PRIZES = {
  moonbird:
    'https://opensea.io/assets/ethereum/0x23581767a106ae21c074b2276d25e5c3e136a68b/8956',
  oddities:
    'https://opensea.io/assets/ethereum/0x1792a96e5668ad7c167ab804a100ce42395ce54d/8956',
};

// For future tournaments,
// set new tournament to isActive: true, all else to false
// order matters, first one is default, second one is previous tournament
export const TOURNAMENTS = {
  valentines: {
    name: 'Valentine\'s Day Massacre',
    apiContext: '2023-02-14',
    id: 'valentines', // used for images
    isActive: false,
    isShown: true,
  },
  kita: {
    name: 'Conquering Mt. Kita',
    apiContext: '2023-03-03',
    id: 'kita', // used for images
    isActive: false,
    isShown: true,
  },
  ides: {
    name: 'Ides of March',
    apiContext: '2023-03-17',
    id: 'ides', // used for images
    isActive: false,
    isShown: true,
  },
  taxes: {
    name: 'Death & Taxes',
    apiContext: '2023-04-10',
    id: 'taxes', // used for images
    isActive: false,
    isShown: true,
  },
  itza: {
    name: 'Chichén Itzá',
    apiContext: '2023-05-01',
    id: 'itza', // used for images
    isActive: false,
    isShown: true,
  },
  roses: {
    name: 'Field of Roses',
    apiContext: '2023-05-22',
    id: 'roses', // used for images
    isActive: false,
    isShown: true,
  },
  hades: {
    name: 'Gates of Hades',
    apiContext: '2023-06-12',
    id: 'hades', // used for images
    isActive: false,
    isShown: true,
  },
  independence: {
    name: 'Independence Day',
    apiContext: '2023-07-03',
    id: 'independence', // used for images
    isActive: true,
    isShown: true,
  },
  champions: {
    name: 'Tournament of Champions',
    apiContext: 'champions-2022-07',
    id: 'champions', // used for images
    isActive: false,
    isShown: false,
  },
  peaceful: {
    name: 'Tournament of The Peaceful',
    apiContext: 'peaceful-2022-07',
    id: 'peaceful', // used for images
    isActive: false,
    isShown: false,
  },
  fallen: {
    name: 'Tournament of The Fallen',
    apiContext: 'fallen-2022-07',
    id: 'fallen', // used for images
    isActive: false,
    isShown: false,
  },
} as const;

export const isQA = ['development', 'qa'].includes(process.env.NEXT_PUBLIC_ENV!.toLowerCase());
export const PEACEFALL_ADDRESS = process.env.NEXT_PUBLIC_PEACEFALL_ADDRESS! as `0x${string}`;
export const POTION_ADDRESS = process.env.NEXT_PUBLIC_POTION_CONTRACT_ADDRES! as `0x${string}`;
