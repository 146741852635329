import { TOURNAMENTS } from '~/constants';
import { TournamentWinner } from '~/server/context/helpers';

export type WarrorJSON = {
  animation_url: string;
  id: number;
  image: string;
  name: string;
  attributes: Array<Attribute>;
  chronicle: Array<Chronicle>;
  context_chronicles: Array<ContextChronicles> | [];
};

// todo: figure out how to change `any` to chronicle
export type ContextChronicles = {
  any: ContextChronicle;
};

export interface ContextChronicle extends ResultType {
  state: number;
}

export type Chronicle = {
  combat_entries: Array<CombatEntry>;
  fatal: null | boolean;
  id: string;
  recorded_at: string;
  round: number;
  victor: number;
};

export type Warrior = {
  set_attack: string | null;
  uri: string;
  warrior_id: number;
  owner: string | undefined;
  warrior_json: WarrorJSON;
  lastAttack?: string;
};

export type WarriorBattle = {
  hp: number | string;
  id: number;
  level: number;
  name: string;
  syndicate?: string;
  type?: string;
  dead: boolean;
  images: WarriorImages;
};

export type WarriorImages = {
  active: string; // show gif
  notActive: string; // show png
  dead: string; // dead dead
  stunned: string; // if not dead
};

export type CombatEntry = {
  attack: null | string;
  owner: string;
  warrior: {
    hp: number;
    id: number;
    level: number;
    name: string;
    syndicate: string;
  };
};

export interface WarriorObfuscated extends Warrior {
  lastAttack?: string;
}

export type WarriorsResult = {
  hasMore: boolean;
  skip: number;
  limit: number;
  warriors: [Warrior];
};

export type AttributeKeys =
  | 'Character'
  | 'Type'
  | 'Hair'
  | 'Garment (Top)'
  | 'Garment (Bottom)'
  | 'Syndicate'
  | 'Weapon'
  | 'HP'
  | 'Level'
  | 'Peace'
  | 'Dead';

export type WarriorNFT = {
  name: string;
  value: string; // utilized by leaderboard
  image: string;
  id: string;
  animation_url: string;
  total: number;
  attributes: Array<LevelAttribute | WarriorAttribute | WarriorAttributeHP>;
};

export type WarriorNFTIndex = {
  current_owner: string;
  warrior: WarriorNFT;
};

export type WarriorAttribute = {
  trait_type: string;
  value: string | number;
};

export interface LevelAttribute extends WarriorAttribute {
  trait_type: 'Type';
  max_value: 5;
  value: number;
}

export type WarriorAttributeHP = {
  trait_type: string;
  value: number;
  max_value: number;
};

export type Attribute = {
  trait_type: string;
  value: string;
  display_type: string | undefined;
};

export type LeaderboardResultPage = {
  results: [LeaderboardResult];
  hasMore: boolean;
  total: number;
  nextPage: number;
  page: number;
  level: number;
  limit: number;
};

export type LeaderboardResult = {
  character: number;
  hp: number;
  level: number;
  name: string;
  rank: number;
  __score__: number;
  __meta__: {
    current_owner: string;
  } & WarrorJSON;
};

export type BattleResult = {
  resultReady: boolean;
  recorded_at?: string;
  id?: string;
  victor?: number;
  round?: number;
  combat_entries?: Array<any>;
};

export type TwitterFollow = {
  isFollowing: boolean;
};

export type DiscordType = {
  address: string;
  discordHandle: string;
  character: string;
};

export type SubmitNFTHolderPayload = {
  address: string;
  code: string;
};

export type DiscordVerificationType = {
  address: string;
  discordUserId: string;
  discordGuildId: string;
};

export type WarriorRarity = {
  rarity: number;
  rank: number;
  level: number;
  current_owner: {
    address: string;
    ens_name: string | null;
  };
  dead: boolean;
  warrior: {
    attributes: {
      Character: string;
      Type: string;
      Syndicate: string;
      HP: number;
    };
    id: number;
    name: string;
  };
};

export type RarityFilters = {
  characters: Array<string>;
  levels: Array<number>;
  syndicates: Array<string>;
  types: Array<string>;
  hps: Array<string>;
  owned: Array<number>;
  contextPairings: Array<number>;
};

export type LevelStats = {
  1: number;
  2: number;
  3: number;
  4: number;
  5: number;
  dead: number;
  burned: number;
};

type syndicateStats = {
  metal: number;
  fire: number;
  timber: number;
  water: number;
  earth: number;
  spirit: number;
  renegade: number;
};

type typeStats = {
  human: number;
  zombie: number;
  alien: number;
};

type hpStats = {
  0: number;
  51: number;
  76: number;
  101: number;
  126: number;
  150: number;
};

export type TournamentParticipants = {
  champions: number[];
  peaceful: number[];
  fallen: number[];
};

export type LeaderboardStats = {
  levelStats: LevelStats;
  syndicateStats: syndicateStats;
  typeStats: typeStats;
  hpStats: hpStats;
  // contexts: TournamentParticipants;
};

export type CampaignTypes = 'war' | 'peace';

export type OwnerRankings = {
  warrior_id: number;
  level: number;
  ranking: number; // note incremented by 1 already
};

export const PotionTypes = {
  blue: 'blue',
  green: 'green',
  purple: 'purple',
};

export type Potion = {
  attributes: [
    {
      trait_type: 'Color';
      value: 'blue' | 'green' | 'purple';
    }
  ];
  id: number;
  image: string;
  name: string;
};

export type CreateVerifyEmail = {
  email: string;
  address: string;
};

export type EmailVerificationType = {
  wallet: string;
  email: string;
  verificationToken?: string;
  verifiedAt?: number;
};

export type ConfirmVerifyEmail = {
  code: string; // email code, not wallet token
  address: string;
};

export type TournamentRegister = {
  token: string; // bearer token
  walletAddress: string;
};

export type GetTournamentRegister = {
  token: string; // bearer token
};

export type TournamentRegisterSuccessResponse = {
  registration: {
    context_id: '2023-02-14';
    wallet_address: string;
  };
};

export type PairingsResponse = [PairingType, PairingType][];

export type PairingType = {
  wallet_address: string;
  warrior_id: number;
};

export type TPairingWarrior = {
  level: number;
  id: number;
  name: string;
  character: string;
  isWinner?: boolean; // added via frontend, not from API
};

export type TTournamentParams = keyof typeof TOURNAMENTS;

export type TournamentOwnerPairingsArgs = {
  contextId: string;
  battleId: number;
  walletAddress: string;
};

export type TournamentPairingsVerboseArgs = {
  contextId: string;
  battleId: number | string;
};

export type TournamentByeArgsByOwner = {
  contextId: string;
  battleId: number;
  walletAddress: string;
};

export type TournamentByes = {
  contextId: string;
};

export type TournamentByeResponse = number[];

export type TournamentPairingsVerboseResponse = TournamentMatchups[];

export type TournamentMatchups = [TPairingWarrior, TPairingWarrior];

export type TournamentResultsByRoundItem = {
  round: number;
  matchups: TournamentPairingsVerboseResponse;
};

export type BracketRound = {
  label: string;
  bracketType: 'grid' | 'bracket' | 'final4' | 'finals';
  battleId: number;
  totalMatchups: number;
};

export type TournamentResultsByRound = TournamentResultsByRoundItem[];

export type ContextsResponse = {
  context_states: [ContextType];
};

export type ContextType = {
  context_id: string;
  is_active: boolean;
  resources: ['RESULTS' | 'PAIRINGS' | 'REGISTRY' | null] | null;
  state: number | null;
};

export type GetWarriorAttackArgs = {
  contextId: string;
  battleId: number;
  warriorId: number;
  token: string;
};

export type GetWarriorAttackResponse = {
  attack: string | null;
};

export type ResultsByWarriorArgs = {
  contextId: string;
  battleId: number;
  warriorId: number;
};

export type ResultsByWarriorResponse = {
  results: [ResultType] | [];
};

export type ContextResultsArgs = {
  contextId: string;
  battleId: number;
};

export type ResultsOrPairingsArgs = {
  contextId: string;
  rounds: number[];
};

export type ContextResultsResponse = [ResultType];

export type ResultType = {
  context_id: string;
  entries: [ResultEntry, ResultEntry];
  state: number;
  victor: number;
};

export type ResultEntry = {
  current_attack: null | string;
  previous_attack: null | string;
  warrior: {
    hp: number;
    id: number;
    syndicate: string; // TODO: add constants
  };
};

// TODO: clean up these undefineds since we know these are required
export type RecordResponse = RecordType[];

export type RecordType = {
  label: string;
  title: string;
  state: number;
  iconImage: string;
  battleResult: 'Win' | 'Tie' | 'Lose' | 'Bye';
  warrior: {
    attack: string | undefined;
    isDefault: boolean;
    hp: number | undefined;
  };
  opponent: {
    attack: string | undefined;
    isDefault: boolean;
    hp: number | undefined;
    id: number | undefined;
    syndicate: string | undefined;
  };
};

export type WalletNotRegisteredError = {
  status: 400;
  errors: [
    {
      code: 'context:wallet-not-registered';
      fields: {
        context: string;
        wallet_address: string;
      };
      messaage: string;
    }
  ];
};

// export type BracketMatchups = BracketMatchup[]

export type MatchupByRound = {
  round: number;
  matchups: TournamentMatchups[];
};

export type BatchAttackTypes =
  | 'random'
  | 'loyalist'
  | 'opponent'
  | 'counterCounter';

export const VALID_ATTACKS: Attacks[] = [
  'water',
  'timber',
  'metal',
  'earth',
  'fire',
];
export type Attacks = 'water' | 'timber' | 'metal' | 'earth' | 'fire';

export type ChallengerAttributes = {
  value: string | number;
  max_value?: number;
  trait_type: string;
};

export type ChallengerContextChroniclesEntries = {
  current_attack: string;
  previous_attack: string | null;
  rank: number;
  warrior: {
    id: number;
    syndicate: string;
    dead: boolean;
    hp: number;
  };
};

export type ChallengerContextChronicles = {
  entries: ChallengerContextChroniclesEntries[];
  victor: number;
  state: number;
  context_id: string;
};

export type ChallengerChronicles = {
  combat_entries: any[];
  victor: number;
  round: number;
  recorded_at: string;
  id: string;
  fatal: boolean;
  context_id: string;
  state: string;
};

export type Challenger = {
  id: number;
  animation_url: string;
  attributes: ChallengerAttributes[];
  name: string;
  chronicle: ChallengerChronicles[];
  context_chronicles: {
    [key: string]: ChallengerContextChronicles[];
  };
  level_boost: number;
  image: string;
};

export type TournamentWinners = {
  tournamentId: TTournamentParams;
  winners?: TournamentWinner[];
};
