import { getDefaultWallets } from '@rainbow-me/rainbowkit';
import { configureChains, createClient, mainnet, goerli } from 'wagmi';
import { infuraProvider } from 'wagmi/providers/infura';
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public';
import { isQA } from '~/constants';

const chainToConnect = isQA
  ? goerli
  : mainnet;

export const { chains, provider } = configureChains(
  [chainToConnect],
  [
    alchemyProvider({ apiKey: process.env.NEXT_PUBLIC_ALCHEMY_KEY! }),
    infuraProvider({ apiKey: process.env.NEXT_PUBLIC_INFURA_ID! }),
    publicProvider(),
  ]
);

const { connectors } = getDefaultWallets({
  appName: 'Peacefall',
  chains
});

export const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});
